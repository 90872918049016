require('./frontend.swiper.js');
require('./frontend.fontawesome.js');
require('./frontend.bootstrap');

// lazy load images and videos
import 'lazysizes';

/* basic lightbox */
import * as basicLightbox from 'basiclightbox'; // simple lightbox for images, videos, etc.

$('a[data-lightbox]').click(function(e) {
    e.preventDefault();

    let imageSrc = $(this).attr('href');
    let caption = $(this).data('caption') || '';

    let lightboxInstance = basicLightbox.create(`
        <figure>
            <button class="btn btn-close" data-lightbox="close"></button>
            <img src="${imageSrc}" alt="${caption}">
            <figcaption>${caption}</figcaption>
        </figure>
    `);
    lightboxInstance.show();
    document.querySelector('[data-lightbox="close"]').onclick = lightboxInstance.close;
});

// input mask for forms
import IMask from 'imask';
window.IMask = IMask;
// core version 6.5.2 + options
import { library, dom, config } from "@fortawesome/fontawesome-svg-core";
config.searchPseudoElements = true;
config.keepOriginalSource = false;

// add all needed icons here in camelcase (also copy it below to library.add) icons and names can be found here https://fontawesome.com/search?o=r&m=free
import {
    faInfoCircle,
    faChevronDown,
    faChevronUp,
    faPhone,
    faEnvelope,
    faArrowRight,
    faChevronRight,
    faLock,
    faHeart,
    faArrowDown,
    faBars,
    faXmark,
    faLocationDot,
    faCheck,
    faExclamationTriangle,
    faMinus,
    faPlus,
    faFile,
    faFileExcel,
    faFileWord,
    faFilePdf,
    faFileZipper,
    faFileVideo,
    faFilePowerpoint,
    faFileImage,
    faFileAudio,
    faFileCode,
    faFileLines,
    faFileCsv,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

// brands
import {
    faFacebookF,
    faXTwitter,
    faXing,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faInfoCircle,
    faChevronDown,
    faChevronUp,
    faPhone,
    faEnvelope,
    faArrowRight,
    faChevronRight,
    faLock,
    faHeart,
    faArrowDown,
    faBars,
    faXmark,
    faLocationDot,
    faCheck,
    faExclamationTriangle,
    faMinus,
    faPlus,
    faFile,
    faFileExcel,
    faFileWord,
    faFilePdf,
    faFileZipper,
    faFileVideo,
    faFilePowerpoint,
    faFileImage,
    faFileAudio,
    faFileCode,
    faFileLines,
    faFileCsv,
    faDownload,

    // brands
    faFacebookF,
    faXTwitter,
    faXing,
);

// watch for changes to the DOM and automatically add the SVG elements
dom.i2svg();
dom.watch();
// core version
import Swiper from 'swiper';

// optional, adjust as needed - be sure to also add to window.swiper below and to!
import { Autoplay } from 'swiper/modules';
// import { Navigation } from 'swiper/modules';
import { Pagination } from 'swiper/modules';
// import { Virtual } from 'swiper/modules';
// import { Keyboard } from 'swiper/modules';
// import { Mousewheel } from 'swiper/modules';
// import { Scrollbar } from 'swiper/modules';
// import { Parallax } from 'swiper/modules';
// import { FreeMode } from 'swiper/modules';
// import { Grid } from 'swiper/modules';
// import { Manipulation } from 'swiper/modules';
// import { Zoom } from 'swiper/modules';
// import { Controller } from 'swiper/modules';
// import { A11y } from 'swiper/modules';
// import { History } from 'swiper/modules';
// import { HashNavigation } from 'swiper/modules';
// import { EffectFade } from 'swiper/modules';
// import { EffectCube } from 'swiper/modules';
// import { EffectFlip } from 'swiper/modules';
// import { EffectCoverflow } from 'swiper/modules';
// import { EffectCards } from 'swiper/modules';
// import { EffectCreative } from 'swiper/modules';
// import { Thumbs } from 'swiper/modules';

// make swiper accessible everywhere
window.swiper = [];
window.swiper.core = Swiper;
window.swiper.modules = [
    Autoplay,
    // Navigation,
    Pagination,
    // Virtual,
    // Keyboard,
    // Mousewheel,
    // Scrollbar,
    // Parallax,
    // FreeMode,
    // Grid,
    // Manipulation,
    // Zoom,
    // Controller,
    // A11y,
    // History,
    // HashNavigation,
    // EffectFade,
    // EffectCube,
    // EffectFlip,
    // EffectCoverflow,
    // EffectCards,
    // EffectCreative,
    // Thumbs,
];
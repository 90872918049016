let resizeTimer;
$(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
        closeAll();
    }, 100);
});


let scrollTimer;
$(window).scroll(function() {
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(function() {
        //countUpFacts();
    }, 100);
});


function closeAll() {
    // close all dropdowns and such
    $('.dropdown.show').removeClass('show');
    $('.dropdown-menu.show').removeClass('show');
    $('[data-toggle="dropdown"]').removeClass('show');
    $('[data-ps-toggle="dropdown"]').removeClass('show');
    $('body').removeClass('blur');
}


const header = $('header');
let headerHeight = header.outerHeight(true);

if ($(window).scrollTop() >= headerHeight) {
    header.addClass('sticky');
}

$(window).scroll(function (e) {
    header.toggleClass('sticky', $(this).scrollTop() >= headerHeight);
});


// smooth scroll animation to #id or .class
$(document).on('click', '[data-ps-scroll]', function(e) {
    e.preventDefault();
    let target = $(this).data('ps-scroll');

    closeAll();

    if($(target).length) {
        let offset = 0;
        let speed = 500;

        // custom offset
        if(typeof $(this).data('offset') !== 'undefined') {
            offset = parseInt( $(this).data('offset') );
        }

        // custom speed
        if(typeof $(this).data('speed') !== 'undefined') {
            speed = parseInt( $(this).data('speed') );
        }

        $('html, body').animate({
            scrollTop: $(target).offset().top - offset
        }, speed);
    }
});


/* custom dropdown */
$(document).on('click', '[data-ps-toggle="dropdown"]', function (e) {
    $(this).toggleClass('show');

    let target = $(this).data('ps-target');
    let body = $(this).data('body');

    if (body) {
        if (body === 'blur') {
            $('body').toggleClass('blur');
        }
    }

    if (target) {
        $(target).toggleClass('show');
    } else {
        $(this).next('.dropdown-menu').toggleClass('show');
    }
});

$(document).on('click', '[data-ps-close="dropdown"]', function (e) {
    let parent = $(this).parents('.dropdown-menu');
    parent.toggleClass('show');
    $('[data-ps-target="#' + parent.attr('id') + '"]').toggleClass('show');
});